import { defineStore } from 'pinia'
import { storage } from "@/firebase/config.js"
import {ref as firebaseRef, getDownloadURL } from 'firebase/storage'


export const useSponzor = defineStore('gallery', {
  state: () => {
    return { 
      galleries: [
        {
            title: 'Sponzoři ročníku 2024',
            cesta: 'sponzori24',
            storage: '/sponzori_24',
        }

      ],
      sponzori: [
        {
          name: 'Geloren',
          storage: '/sponzori_24',
          obrazek: 'Logo Geloren.png',
          odkaz: 'https://www.gehab.cz/',
          cesta: 'sponzori24',
        },        
        {
          name: 'Pučálka krmiva',
          storage: '/sponzori_24',
          obrazek: 'Pucalka_krmiva_brown.png',
          odkaz: 'https://www.krmiva-pucalka.cz/',
          cesta: 'sponzori24',
          text: 'Pučálka krmiva - sponzor longové trati'
        },
        {
          name: 'Nakladatelství Plot',
          storage: '/sponzori_24',
          obrazek: 'PLOT_new logo_MODRE.jpg',
          odkaz: 'https://www.plotknihy.cz/cs/',
          cesta: 'sponzori24',
        },        
        {
          name: 'TraumaPet',
          storage: '/sponzori_24',
          obrazek: 'TraumaPet.png',
          odkaz: 'https://www.traumapet.cz/',
          cesta: 'sponzori24',
        },

        {
          name: 'Papa Don',
          storage: '/sponzori_24',
          obrazek: 'papadon png.png',
          odkaz: 'https://www.papadon.cz/',
          cesta: 'sponzori24',
          text: 'BARF + doplňky k BARF stravě +  sportovní doplňky výživy pro PSY + sportovní doplňky výživy pro LIDI + ručně vyráběné čmuchací hračky'
        },
        {
          name: 'MushGo',
          storage: '/sponzori_24',
          obrazek: 'mushgo.png',
          odkaz: 'https://www.mushgo.cz/',
          cesta: 'sponzori24',
        },

      ]


     }
  },

  getters: {
    //získání jedné galerie podle předané param z adresního řádku
    getGalleryDetail: (state) => {
      return (cesta) => {
        return state.galleries.filter(gallery => {return gallery.cesta === cesta})[0]
      }
    },

    getSponsors: (state) => {
      return (cesta) => {
        return state.sponzori.filter(sponzor => {return sponzor.cesta === cesta})
      }
    }
    

  },


  actions: {
    //vlastní funkce převedení názvů obrázků na jejich adresy
    async getAdress (imagesRef, nazev) {
      try {
        const spaceRef = firebaseRef(imagesRef, nazev)
        const url = await getDownloadURL(spaceRef)
        return url
      } catch (error) {
        // Zpracujte případné chyby
        console.error('Chyba při získávání adresy obrázku:', error)
        return null // nebo jiná vhodná hodnota v případě chyby
      }
    }
    

  },






})