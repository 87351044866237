<template>

    <CeleMenu />
<!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <!--potvrzení odeslání přihlášky-->
        <div class="col-12 q-pa-xl">
         <h3>Přihlašovací formulář</h3>
         <p class="q-mb-md">1. uzávěrka - 15. 8. 2024 - končí možnost objednání vodítek<br>
            2. uzávěrka - 15. 9. 2024
            <br><b>přihlašování prodlouženo do 23.9.2024</b></p>
            <div class="q-mt-md" v-if="currentDate > druhaUzaverka">
                <h2>Přihlašování bylo ukončeno</h2>
            </div>

            <div class="q-mt-md" v-if="currentDate < druhaUzaverka">
                

                <form  @submit.prevent="handleSubmit" 
                    class="q-gutter-sm"
                    shouldFocus: true
                    >
                    
                    <div class="row">
                        <q-input 
                            outlined
                            type="text"
                            label="jméno"
                            v-model="jmeno" 
                            class="col-12 col-md-6"
                        />
                        <q-input 
                            outlined
                            type="text"
                            label="příjmení"
                            v-model="prijmeni" 
                            class="col-12 col-md-6"
                        />
                    </div>
                    <div class="row">
                        <q-input 
                            outlined
                            type="text"
                            label="Ulice + č.p."
                            v-model="ulice" 
                            class="col-12 col-md-5"
                        />
                        <q-input 
                            outlined
                            type="text"
                            label="Město"
                            v-model="mesto" 
                            class="col-12 col-md-5"
                        />
                        <q-input 
                            outlined
                            type="text"
                            label="Stát"
                            v-model="stat" 
                            class="col-12 col-md-2"
                        />
                    </div>
                    <div class="row">
                        <q-select 
                            outlined 
                            v-model="predcisli" 
                            :options="pred" 
                            label="Předčíslí" 
                            class="col-3 col-sm-2 col-md-1"
                        />
                        <q-input 
                            outlined
                            type="text"
                            label="Telefon"
                            v-model="tel" 
                            mask="### ### ###"
                            class="col-9 col-sm-10 col-md-5"
                        />
                        <q-input 
                            outlined
                            type="text"
                            label="E-mail"
                            v-model="email" 
                            class="col-12 col-md-6"
                        />
                    </div>
                    <div class="row justify-between">
                        <q-select 
                            outlined 
                            v-model="selectedPsiCount" 
                            :options="pocet" 
                            label="Počet psů" 
                            class="col-12 col-sm-2"
                        />
                        <div class="col-11 col-sm-10">
                            <div v-for="(pes, index) in psi" :key="index" class="row justify-end q-mb-xs">
                                <span class="self-start">
                                    O možnosti zápisu pracovní třídy zatím jednáme, nemůžeme ho slíbit. Psa u kterého chcete zápis pro pracovní třídu je nutné přihlásit plným jménem z PP.
                                </span>
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Jméno psa"
                                    v-model="pes.jmenoPsa" 
                                    class="col-12 col-md-6"
                                />
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Plemeno psa"
                                    v-model="pes.plemenoPsa" 
                                    class="col-12 col-md-6"
    
                                />
                                <q-input 
                                    outlined
                                    type="date"
                                    label="Datum narození psa"
                                    v-model="pes.datumPsa" 
                                    class="col-12 col-md-6"
    
                                />
                                <q-input 
                                    outlined
                                    type="number"
                                    label="Číslo čipu"
                                    v-model="pes.cisloCipu" 
                                    class="col-12 col-md-6"
                                />
                                <q-select 
                                    outlined 
                                    v-model="pes.pracovniTrida" 
                                    :options="ano_ne" 
                                    label="zápis pracovní třídy" 
                                    style="width: 100%;"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <q-select 
                            outlined 
                            v-model="zavod" 
                            :options="trasa" 
                            label="Délka trasy" 
                            class="col-12 col-md-6"
                        />
                        <q-select 
                            outlined 
                            v-model="kategorie" 
                            :options="kat" 
                            label="Kategorie" 
                            class="col-12 col-md-6"
                        />
                    </div>
                    <div class="row q-mt-md content-end items-end">
                        <div v-if="currentDate > prvniUzaverka" class="col-12"><b>Po první uzávěrce už není možné objednat vodítka</b></div>
                        <div class="col-12 col-md-6">
                            Nenasákavé vodítko pro psy o hmotnosti do 15 kg (u nás testováno na 20 kg) - cena za kus 270 Kč
                            <q-select 
                                outlined 
                                v-model="voditkoMale" 
                                :options="voditkaVyber()" 
                                label="Vodítko pro menší psy" 
                                required 
                            />
                        </div>
                        <div class="col-12 col-md-6">
                            Nenasákavé vodítko pro psy o hmotnosti do 25 kg (u nás testováno na alaskánech) - cena za kus 350 Kč
                            <q-select 
                                outlined 
                                v-model="voditkoVelke" 
                                :options="voditkaVyber()" 
                                label="Vodítko pro větší psy" 
                                required 
                            />
                        </div>
                    </div>

                    <q-input 
                        outlined
                        type="textarea"
                        label="Poznámka pro pořadatele" 
                        v-model="pozn"
                    />
                    <q-toggle
                        :label="gdpr"
                        color="primary"
                        false-value= "Prosím udělte svůj souhlas se zpracováním osobních údajů"
                        true-value="Děkujeme za udělení souhlasu se zpracováním osobních údajů"
                        v-model="gdpr"
                    />
                    <q-icon class="touch-hide" name="info" color="primary" size="1.7rem" @mouseover="handleMouseOver"/>
                    <q-icon class="touch-only" name="info" color="primary" size="1.7rem" @click="handleMouseOver"/>
                    <div v-if="showGdpr" class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                        <PotvrzeniPrihlaseni 
                            @toggleDotaz = "handleMouseOver"
                            theme = "gdpr"
                            >
                            <p><b>Souhlas se zpracováním osobních údajů GDPR</b></p>
                            <p>Závodník souhlasí s tím, že údaje, uvedené v této přihlášce budou pořadatelem závodu s názvem
                                Dogtrekking za pokladem Voka IV. z Holštejna zpracovány za účelem řádného uspořádání  
                                výše jmenovaného závodu. Osobní údaje, kterými jsou jméno, příjmení, emailová adresa, 
                                telefonní číslo, adresa závodníka a informace o psech se kterými se bude závodu účastnit budou archivovány 
                                až do odvolání emailem. </p>
                            <p>V případě nutnosti budou údaje předány třetí osobě, které je pořadatel povinen je poskytnout 
                                (např. zdravotní pojišťovna, záchranná služba atd.).</p>
                            <p>Závodník souhlasí s tím, že mu budou na jím uvedenou e-mailovou adresu, zasílány relevantní informace, 
                                nutné pro jeho účast na závodě. Dále závodník souhlasí, že jeho jméno, příjmení, budou 
                                zveřejněny ve startovní a výsledkové listině. Výsledková listina bude uložena a zveřejněna na webových stránkách pořadatele, 
                                jako veřejně dohledatelná informace dokumentující historii daného závodu.</p>
                            <p>Tento můj souhlas se vztahuje též na předání výše zpracovávaných osobních údajů technickému výboru 
                                dogtrekkingu, pro účely zveřejnění výsledkové listiny na oficiálních webových stránkách, 
                                oficiální FB stránce či pro účely statistické.</p>
                            <p>V souladu s § 85 zákona č. 89/2012 Sb., občanského zákoníku a v souladu s dalšími právními předpisy 
                                závodník zároveň souhlasí s užitím svých písemných projevů, obrazových snímků, své podobizny a obrazových 
                                i zvukových záznamů týkajících se jeho osoby nebo jeho projevů osobní povahy pořízených v souvislosti 
                                s pořádáním závodu a předáváním výhry pro komerční účely na všech komunikačních médiích daného závodu 
                                bez ohledu na jejich povahu a určení všemi obvyklými způsoby, s jejich následnou úpravou a 
                                s jejich případným spojením s jinými díly nebo zařazením do souborného díla. Tento souhlas je udělen 
                                bezplatně, bez věcného, časového, množstevního a územního omezení, a lze jej kdykoli písemně odvolat
                                na emailové adrese pořadatele.</p>
                            <p>Úcastník potvrzuje, že byl seznámen se skutečností, že poskytování osobních údajů je dobrovolné, 
                                ovšem odepření poskytnutí údajů nebo odvolání souhlasu s jejich zpracováním nebo předáním třetím 
                                osobám (jak je uvedeno výše) může mít za následek omezení nebo vyloučení některých služeb 
                                poskytovaných ve prospěch závodníka pořadatelem či jeho samotnou účast na závodech.</p>
                        </PotvrzeniPrihlaseni>
                    </div>

                    <!--kontrola odeslání před vyhlášením data závodu-->
                    <div v-if="staryRok && !isPending" class="q-mt-lg">
                        <q-btn @click.prevent="toggleDotaz" style="background: #726d65; color: white">Odeslat přihlášku</q-btn>
                    </div>
                    <!--odeslání po vyhlášení data závodu-->
                    <div v-else-if="!isPending" class="q-mt-lg">
                        <q-btn type="submit" style="background: #726d65; color: white">Odeslat přihlášku</q-btn>
                    </div>
                    <!--disabled btn po dobu zpracování kontroly a odesílání přihlášky-->
                    <div v-else-if="isPending">
                        <q-btn type="submit" disable style="background: #726d65; color: white">Kontrola a ukládání přihlášky</q-btn>
                                    
                    </div>
                
                   
                    <div v-if="showDotaz" class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                        <PotvrzeniPrihlaseni 
                            @toggleDotaz = "toggleDotaz"
                            @handleSubmit = "handleSubmit"
                        />
                    </div>

                    <div v-if="showChybu" class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                        <PotvrzeniPrihlaseni 
                            @toggleDotaz = "toggleChybu"
                            theme = "warning"
                        >
                        <q-icon name="warning" color="white" size="4rem" /> {{ chyba }}
                        </PotvrzeniPrihlaseni>
                    </div>
                    <div v-if="prihlaseno">
                    
                        <PotvrzeniPrihlaseni 
                            @toggleDotaz = "togglePotvrzeni"
                            theme = "gdpr"
                            >
                            Vaše přihláška byla úspěšně uložena. Podklady k platbě najdete ve své emailové schránce.
                        </PotvrzeniPrihlaseni>
                    </div>   
        
                </form>
            </div>
        </div>
    </div>
    <div class="col-12 self-end footer item" style="height: 5vh;">
        <FooterSection />
    </div>


</template>

<script setup >

import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'
import PotvrzeniPrihlaseni from '@/components/Potvrzeni.vue'

//import reCaptcha from '@/components/reCaptcha.vue'
import { ref } from 'vue'
import { watch } from 'vue'
import { computed } from 'vue'
import { useMeta } from 'vue-meta'

import { db } from '../firebase/config'
import { collection, getDocs, query, serverTimestamp, where, setDoc, doc, Timestamp } from 'firebase/firestore'


// title pro SEO
useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - přihlašování na ročník 2024' })


//datum - pro ověřovací modál a pro určení uzávěrky
// Aktuální datum jako ref
const currentDate = ref(new Date())
// termíny uzávěrek
const prvniUzaverka = new Date('2024-08-15')
const druhaUzaverka = new Date('2024-09-24')

// konstanty k provázání k formuláři
const jmeno = ref('')
const prijmeni = ref('')
const zavod = ref(null)
const trasa = ['long','mid', 'vycházka(short)']
const pozn = ref(null)
const kategorie = ref(null)
const kat = ref(null)
const ulice = ref('')
const mesto = ref('')
const stat = ref('')
const tel = ref(null)
const email = ref('')
const voditkoMale = ref(0)
const voditkoVelke = ref(0)
const psi = ref([]) // Pole pro uchování informací o jednotlivých psech
const pocet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const voditkaVyber = () => {
    if (currentDate.value <= prvniUzaverka ){
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
    else {
        return [0]
    }
}
const pred = ['+420', '+421']
const predcisli = ref('+420')
const ano_ne = ['ne', 'ano']

const selectedPsiCount = ref(0); // Počet psů vybraný v q-select

// Funkce pro aktualizaci pole psi na základě vybraného počtu v q-select
const updatePsi = () => {
    psi.value = Array.from({ length: selectedPsiCount.value }, () => ({
    jmenoPsa: '',
    plemenoPsa: '',
    datumPsa: null,
    cisloCipu: '',
    pracovniTrida: 'ne',
    }))
}

// Volání updatePsi při změně vybraného počtu psů
watch(selectedPsiCount, updatePsi)

// Watcher sleduje změny v prvním q-select a aktualizuje možnosti pro druhý q-select
watch([zavod, selectedPsiCount ], ([newValue, newValue2], [oldValue, oldValue2]) => {
    if (newValue === oldValue &&  newValue2 > 0 && oldValue2 > 0) {
    } else if (newValue === 'long' && newValue2 > 0) {
        kat.value = ['DTM 1: muži do 40-ti let včetně', 'DTW 1: ženy do 35-ti let včetně', 'DTM 2: muži nad 40 let', 'DTW 2: ženy nad 35 let']
        kategorie.value = null        
    } else if (newValue === 'long' && newValue2 === 0) {
        kat.value = ['PLYŠÁK: všichni závodníci bez psa']
        kategorie.value = 'PLYŠÁK: všichni závodníci bez psa'
    } else if (newValue === 'mid' && newValue2 > 0) {
        kat.value = ['DMM 1: muži do 40-ti let včetně', 'DMW 1: ženy do 35-ti let včetně', 'DMM 2: muži nad 40 let', 'DMW 2: ženy nad 35 let']
        kategorie.value = null
    } else if (newValue === 'mid' && newValue2 === 0) {
        kat.value = ['PLYŠÁK: všichni závodníci bez psa']
        kategorie.value = 'PLYŠÁK: všichni závodníci bez psa'
    } else if (newValue === 'vycházka(short)') {
        kat.value = ['vycházka(short)']
        kategorie.value = 'vycházka(short)'
    } else {
        kat.value = []
    }
})

// GDPR
const souhlas = 'Prosím udělte svůj souhlas se zpracováním osobních údajů'
const gdpr = ref(souhlas)

//zobrazení disabled btn po odeslání přihlášky
const isPending=ref(false)

// zpracování odesílacího btnu
const handleSubmit = async() => {
    isPending.value = true
    showDotaz.value = false
    const isValid = await validateForm()
    if (isValid) {
        vypocetStartovneho()
        sendDataToServer()
    }
}

// validace na nevyplněná poviná pole a ověření délky tel. čísla a formátu emailu 
const chyba = ref(null)
const validateForm = async() => {
    if (isWhiteSpaceString(jmeno.value)) {
        chyba.value = 'Vyplňte prosím své jméno'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (isWhiteSpaceString(prijmeni.value)) {
        chyba.value = 'Vyplňte prosím své příjmení'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (isWhiteSpaceString(ulice.value)) {
        chyba.value = 'Vyplňte prosím svou adresu - ulici'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (isWhiteSpaceString(mesto.value)) {
        chyba.value = 'Vyplňte prosím svou adresu - město'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (isWhiteSpaceString(stat.value)) {
        chyba.value = 'Vyplňte prosím svou adresu - stát'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (tel.value === null) {
        chyba.value = 'Vyplňte prosím své telefonní číslo'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (tel.value.length < 9 ) {
        chyba.value = 'Vyplňte prosím správně své telefonní číslo'
        toggleChybu()
        isPending.value = false
        return false
    }
    const isValueUnique = await isFieldValueUnique( tel.value);
    if (!isValueUnique) {
        console.error('Hodnota není unikátní!')
        chyba.value = 'Tento telefon je uvedený již u jiného závodníka. Prosím opravte telefonní číslo.'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (email.value === null) {
        chyba.value = 'Vyplňte prosím svůj email.'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (!isValidEmail(email.value)) {
        chyba.value = 'Neplatný formát emailu.'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (zavod.value === null) {
        chyba.value = 'Vyberte prosím délku trasy.'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (kategorie.value === null) {
        chyba.value = 'Vyberte prosím kategorii'
        toggleChybu()
        isPending.value = false
        return false
    }
    if (gdpr.value === souhlas) {
        chyba.value = 'Souhlas se zpracováním osobních údajů je pro přihlášení nutný'
        toggleChybu()
        isPending.value = false
        return false
    }
    
return true
}



const startovne = ref('')
const vypocetStartovneho = () => {
    if (currentDate.value <= prvniUzaverka ) {
        if (zavod.value === 'long') {
            startovne.value = 500
        } else if (zavod.value === 'mid') {
            startovne.value = 400
        } else if (zavod.value === 'vycházka(short)') {
            startovne.value = 250        
        } else {
            console.log ('neznámá délka trasy')
        }
        console.log(startovne.value)
    } else if (currentDate.value >= prvniUzaverka && currentDate.value <= druhaUzaverka) {
        if (zavod.value === 'long') {
            startovne.value = 600
        } else if (zavod.value === 'mid') {
            startovne.value = 500
        } else if (zavod.value === 'vycházka(short)') {
            startovne.value = 350        
        } else {
            console.log ('neznámá délka trasy')
        }
        console.log(startovne.value)

    } else {
        console.log ('už je po druhé uzávěrce')
    }
}

//ošetření prázdných znaků ve formuláři při validaci
const isWhiteSpaceString = (str) => /^\s*$/.test(str)

//ověření formátu emailu
const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

//přepínač modálu s chybou
const showChybu = ref(false)
const toggleChybu = (() => {
    showChybu.value = !showChybu.value
})

// ověření unikátnosti telefonního čísla
const isFieldValueUnique = async (value) => {
// Zkontroluje, zda hodnota je unikátní v rámci daného pole ve Firestore
    const querySnapshot = await getDocs(query(collection(db, 'zavodniciDtHolstejn', 'DT2024', 'public'), where( 'telefon', '==', value))) 
    return querySnapshot.empty
}

//odeslání dat na server s následným zobrazením modálu a vynulováním formuláře
const prihlaseno = ref(false)
const sendDataToServer = async() => {

    try {
        const publicCollectionRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'public')
        const publicData = {
            jmeno: jmeno.value.trim(),
            prijmeni: prijmeni.value.trim(),
            telefon: tel.value,
            psi: psi.value.map(pes => ({
                jmenoPsa: pes.jmenoPsa,
                plemenoPsa: pes.plemenoPsa,
                datumPsa: pes.datumPsa ? Timestamp.fromDate(new Date(pes.datumPsa)) : null,
                cisloCipu: pes.cisloCipu,
                pracovka: pes.pracovniTrida
            })),
            trasa: zavod.value,
            kategorie: kategorie.value,
            zaplaceno: false,
            pocetPsu: selectedPsiCount.value,

        }

        await setDoc(doc(publicCollectionRef, tel.value), publicData)
        const privateCollectionRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'private')
        const privateData = {
            ulice: ulice.value.trim(),
            mesto: mesto.value.trim(),
            stat: stat.value.trim(),
            predcisli: predcisli.value,
            email: email.value,
            filteremail: upravitText(email.value),
            zaplacenoCastka: 0, 
            zaplacenoDate: null,
            voditkoMale: voditkoMale.value,
            voditkoVelke: voditkoVelke.value,
            poznamka: pozn.value,
            poznamkaPoradatele: null,
            poznamkaVyrizenaDate: null,
            poznVyrizena: false,
            filterjmeno: upravitText(jmeno.value),
            filterprijmeni: upravitText(prijmeni.value),
            createdAt: serverTimestamp(),
            jmeno: jmeno.value.trim(),
            prijmeni: prijmeni.value.trim(),
            telefon: tel.value,
            psi: psi.value.map(pes => ({
                jmenoPsa: pes.jmenoPsa,
                plemenoPsa: pes.plemenoPsa,
                datumPsa: pes.datumPsa ? Timestamp.fromDate(new Date(pes.datumPsa)) : null,
                cisloCipu: pes.cisloCipu,
                pracovka: pes.pracovniTrida
            })),
            trasa: zavod.value,
            kategorie: kategorie.value,
            zaplaceno: false,
            pocetPsu: selectedPsiCount.value,
            startovne: startovne.value


        }
        await setDoc(doc(privateCollectionRef, tel.value), privateData)



        togglePotvrzeni()
        isPending.value = false
        formToNull()
    } catch (error) {
        console.error('Error during data submission:', error)
    }
}

//úprava textu pro vyhledávání
const upravitText = (text) => {
    let upravenyText = text.trim();
    upravenyText = upravenyText.toLowerCase();
    upravenyText = upravenyText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return upravenyText;
}

//potvrzení přihlášení
const togglePotvrzeni = () => {
    prihlaseno.value = !prihlaseno.value
}

//nulování formuláře
const formToNull = () => {
    jmeno.value = ''
    prijmeni.value = ''
    ulice.value = ''
    mesto.value = ''
    stat.value = ''
    predcisli.value = '+420'
    tel.value = ''
    email.value = ''
    selectedPsiCount.value = 0
    psi.value = []
    zavod.value = null
    kategorie.value = null
    voditkoMale.value = 0
    voditkoVelke.value = 0
    pozn.value = ''
}

// koncové datum pro zobrazení modálu s dotazem, zda se chce závodník opravdu přihlásit
const endDate = new Date('2023-12-31')
// Funkce pro určení, zda je datum v požadovaném rozmezí
const staryRok = computed(() => {
    return currentDate.value <= endDate;
})

// přepínač modálu s ověřením, zda se závodník vážně chce přihlásit
const showDotaz = ref(false)
const toggleDotaz = (() => {
    showDotaz.value = !showDotaz.value
})

// přepínač textu gdpr
const showGdpr = ref(false)
const handleMouseOver =() => {
    showGdpr.value = !showGdpr.value
}

</script>

<style>

</style>