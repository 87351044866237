<template>

    <q-table
        :rows="zavodnik"
        :columns="columns"
        row-key="name"
        :rows-per-page= "zavodnik.length"
        :rows-per-page-options="[zavodnik.length, 5, 10, 20]"

        :rows-per-page-label="'Řádků na stránku'"

    >

    </q-table>
</template>

<script setup>
const props = defineProps(['zavodnik'])


import { ref } from 'vue'

    const columns = [
        {
            label: 'jméno',
            name: 'jmeno',
            required: true,
            align: 'left',
            field: row => row.jmeno,
            format: val => `${val}`,
            sortable: true
        },
        { name: 'prijmeni', align: 'center', label: 'příjmení', field:row => row.prijmeni, sortable: true },
        { name: 'trasa', align: 'center', label: 'trasa', field: row => row.trasa, sortable: true},
        { name: 'kategorie', align: 'center', label: 'kategorie', field: row => row.kategorie, sortable: true},
        { name: 'pocetPsu', align: 'center', label: 'pečet psů', field:row => row.pocetPsu, sortable: true },
        {
            name: 'psi',
            align: 'center',
            label: 'Psi',
            field: row => {
            // Zkontrolujte, jestli závodník má pole 'psi', a pokud ano, vraťte jejich jména spojená čárkou
            if (row.psi && row.psi.length > 0) {
                return row.psi.map(pes => pes.jmenoPsa).join(', ');
            }
            return 'Žádní psi';
            },
            sortable: false // Nemá smysl řadit podle psů
        }
    ]



</script>

<style>

</style>