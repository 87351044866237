<template>
  <NavBar />
    <div class="q-mt-lg" v-if="zavodnik.length">
      <PeopleAndDogsTab 
          :zavodnik="zavodnik"
      />
  </div>
</template>

<script setup>
// komponenty
  import NavBar from '@/components/admin/NavBar.vue'
  import { ref, onMounted, watchEffect } from 'vue'
  import { db } from '@/firebase/config'
  import { collection, onSnapshot } from 'firebase/firestore'


  import PeopleAndDogsTab from '@/components/admin/PeopleAndDogsTab.vue'


  const zavodnik = ref([])


  const ziskejData = () => {
      const unsub = onSnapshot(collection(db,'zavodniciDtHolstejn', 'DT2024', 'private'), snapshot => {
          let results = []
          snapshot.docs.forEach(doc => {
              results.push({ ...doc.data(), id: doc.id })
              zavodnik.value = results
              //console.log(zavodnik.value)
          })
      })
      watchEffect((onInvalidate) => {
          onInvalidate(() => unsub())
      })
  }

  onMounted(() => {
      ziskejData()
  })

  console.log(zavodnik.value)

</script>

<style>

</style>