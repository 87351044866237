<template>
  <div class="navbar row justify-between q-pa-lg q-mb-md shadow-3 items-center">
    <div class="col-8 col-md-6 q-gutter-md" style="height: 80%;"> 
        <div v-if="user">přihlášen jako: <b>{{ user.email }}</b></div>
        <div class="row" style="font-size: large">
            <div class="q-mr-lg">
                <router-link to="/admin/main">Vyhledávání</router-link>
            </div>            
            <div class="q-mr-lg">
                <router-link to="/admin/dogs">Lidi a psi</router-link>
            </div>
            <div class="q-mr-lg">
                <router-link to="/admin/stats">Statistiky</router-link>
            </div>
            <div >
                <router-link to="/admin/news">Novinky</router-link>
            </div>

        </div>
    </div>
    <div class="col-4">
        <div class="row reverse">
        <q-btn 
            @click="handleLogout"
            style="background: #726d65; color: white"
            >
            Odhlásit
        </q-btn></div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase/config'
import { signOut } from 'firebase/auth'
import { useRouter } from 'vue-router'

import getUser from '@/composables/getUser'



export default {

    setup () {

        const { user } = getUser()
        const router = useRouter()

        const handleLogout = () => {
            signOut(auth)
            router.push('/admin/login')
        }

        return {
            handleLogout,
            user,
        }
    }

}
</script>

<style>

</style>